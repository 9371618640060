<template>
  <div>
    <CRow>
      <CCol col="12">
           <CCard class="cards">
          <form id="updateUser" @submit="updateUser" >
          <CCardHeader>
            <CCol col="6" class="styleHeader"> {{ $t('message.updateUser') }}</CCol>
          </CCardHeader>
          <CCardBody>
             <CRow>
              <CCol sm='6'>
                <div class='form-group'>
                    <label for='role'>
                      {{$t('message.role')}}
                      <span class='star'>*</span>
                    </label>
                    <div>
                      <multiselect
                        v-model='selectedRole'
                        id='role_id'
                        :options='roles'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label='name'
                        track-by='name'
                      >
                        <span slot='noOptions'>{{$t('message.emptyList')}}</span>
                        <span slot='noResult'>{{$t('message.noResult')}} </span>
                      </multiselect>
                    </div>
                  </div>
              </CCol>
               <CCol sm="6">
                  <div class="required form-group">
                    <label>
                      {{ $t("message.office") }}
                    </label>
                     <multiselect
                        class='required'
                        v-model='selectedOffice'
                        id='job_id'
                        :options='offices'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label='name'
                        track-by='name'
                        required='required'
                      >
                        <span slot='noOptions'>{{$t('message.emptyList')}}</span>
                        <span slot='noResult'>{{$t('message.noResult')}} </span>
                      </multiselect>
                  </div>
                </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput :label=" $t('message.userName')"
                  id="name"
                  v-model="user.name"
                  pattern="\D.{2,}"
                  oninvalid="setCustomValidity('The name must consist of letters and is not acceptable to consist of numbers')"
                  :placeholder=" $t('message.name') "
                  :invalidFeedback="errors.name"
                />
              </CCol>
              <CCol sm="6">
                <CInput :label=" $t('message.userEmail')"
                  id="email"
                  v-model="user.email"
                  :placeholder=" $t('Example@example.com') "
                  :invalidFeedback="errors.email"
                />
              </CCol>
            </CRow>
              <CRow>
              <CCol sm="6">
                <CInput :label=" $t('message.password')"
                  id="password"
                  v-model="user.password"
                  type ="password"
                  :placeholder=" $t('message.password') "
                  :invalidFeedback="errors.password"
                ></CInput>
              </CCol>
              <CCol sm="6">
                <CInput :label=" $t('message.confirmPasssword')"
                  id="confirmPasssword"
                  v-model="user.password_confirmation"
                  type ="password"
                  :placeholder=" $t('message.confirmPasssword') "
                  :invalidFeedback="errors.confirmPasssword"
                ></CInput>
              </CCol>
            </CRow>
            <CRow>
               <CCol col="6" style="font-size: 15px;color:red"> * {{ $t('message.editpasswordMessage') }}</CCol>
            </cRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" size="sm" color="primary" >
            <CIcon name="cil-check-circle"/> {{ $t('message.save') }}</CButton>
              &emsp;
            <CButton type="button" size="sm" color="btn btn-primary" @click="goBack">
            <CIcon name="cil-ban"/> {{ $t('message.back') }} </CButton>
          </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'updateUser',
  data: function () {
    return {
      user: {},
      roles: [],
      selectedRole: '',
      offices: [],
      selectedOffice: '',
      errors: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}offices`)
      .then((response) => {
        this.offices = response.data.data
      })
    const roles = []
    this.$http
      .get(`${this.$hostUrl}roles`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          roles.push({ id: value.id, name: value.name })
        })
        this.roles = roles
      })
    this.$http
      .get(`${this.$hostUrl}users/${this.$route.params.id}`)
      .then((response) => {
        this.user = response.data.data
        this.selectedRole = {
          id: this.user.roles[0].id,
          name: this.user.roles[0].name
        }
        this.selectedOffice = {
          id: response.data.data.office.id,
          name: response.data.data.office.name
        }
      })
    console.log(this.selectedOffice)
  },
  methods: {
    updateUser: function (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('name', this.user.name)
      formData.append('email', this.user.email)
      if (this.user.password) {
        formData.append('password', this.user.password)
        formData.append('password_confirmation', this.user.password_confirmation)
      }
      formData.append('role_id', this.selectedRole.id)
      formData.append('office_id', this.selectedOffice.id)
      formData.append('_method', 'PUT')

      this.postRequest(formData, `${this.$hostUrl}users/${this.$route.params.id}`, '/users')
    },
    goBack () {
      this.productsOpened ? this.$router.go(-1) : this.$router.push({ path: '/users' })
    }
  }
}

</script>
